define("discourse/plugins/discourse-sift/discourse/components/admin-disagree-flag-dropdown", ["exports", "@ember/object", "@ember/service", "@ember-decorators/component", "discourse-i18n", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-sift/admin/models/sift-mod"], function (_exports, _object, _service, _component, _discourseI18n, _dropdownSelectBox, _siftMod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminDisagreeFlagDropdown = dt7948.c(class AdminDisagreeFlagDropdown extends _dropdownSelectBox.default {
    static #_ = (() => dt7948.g(this.prototype, "adminTools", [_service.service]))();
    #adminTools = (() => (dt7948.i(this, "adminTools"), void 0))();
    nameProperty = "label";
    isDisabled = false;
    headerIcon = "thumbs-o-down";
    computeHeaderContent() {
      let content = super.computeHeaderContent(...arguments);
      content.name = `${(0, _discourseI18n.i18n)("sift.actions.disagree.title")}...`;
      return content;
    }
    sendAndDisable(reason) {
      if (reason === "other") {
        this.send("disagree_other", reason);
      } else {
        this.send("disagree", reason);
        this.set("isDisabled", true);
      }
      if (this.onReportComplete) {
        this.onReportComplete(reason);
      }
    }
    computeContent() {
      const content = [];
      content.push({
        icon: "cog",
        id: "disagree-false-positive",
        action: () => this.sendAndDisable("false_positive"),
        label: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_false_positive.title"),
        description: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_false_positive.description")
      });
      content.push({
        icon: "far-image",
        id: "disagree-too-strict",
        action: () => this.sendAndDisable("too_strict"),
        label: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_too_strict.title"),
        description: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_too_strict.description")
      });
      content.push({
        icon: "far-edit",
        id: "disagree-user-edited",
        action: () => this.sendAndDisable("user_edited"),
        label: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_user_edited.title"),
        description: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_user_edited.description")
      });
      content.push({
        icon: "far-dot-circle",
        id: "disagree-other",
        action: () => this.sendAndDisable("other"),
        label: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_other.title"),
        description: (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_other.description")
      });
      return content;
    }
    mutateValue(value) {
      const computedContentItem = this.get("computedContent").findBy("value", value);
      (0, _object.get)(computedContentItem, "originalContent.action")();
    }
    disagree(reason) {
      let flaggedPost = this.get("post");
      _siftMod.default.disagree(flaggedPost, reason);
    }
    static #_2 = (() => dt7948.n(this.prototype, "disagree", [_object.action]))();
    disagree_other(reason) {
      let flaggedPost = this.get("post");

      // eslint-disable-next-line no-alert
      let otherReason = prompt((0, _discourseI18n.i18n)("sift.actions.disagree_due_to_other.prompt"), (0, _discourseI18n.i18n)("sift.actions.disagree_due_to_other.default"));
      if (otherReason == null || otherReason === "") {
        // eslint-disable-next-line no-alert
        alert((0, _discourseI18n.i18n)("sift.actions.disagree_due_to_other.error"));
        this.set("isDisabled", false);
      } else {
        _siftMod.default.disagreeOther(flaggedPost, reason, otherReason);
        this.set("isDisabled", true);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "disagree_other", [_object.action]))();
  }, [(0, _component.classNames)("disagree-flag", "admin-disagree-flag-dropdown")]);
  var _default = _exports.default = AdminDisagreeFlagDropdown;
});