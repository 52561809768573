define("discourse/plugins/discourse-sift/discourse/controllers/admin-plugins-sift", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/plugins/discourse-sift/admin/models/sift-queue"], function (_exports, _controller, _object, _computed, _ajaxError, _siftQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsSiftController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "sortedPosts", [(0, _computed.sort)("model", "postSorting")]))();
    #sortedPosts = (() => (dt7948.i(this, "sortedPosts"), void 0))();
    postSorting = ["id:asc"];
    enabled = false;
    performingAction = false;
    refresh() {
      this.set("performingAction", true);
      _siftQueue.default.findAll().then(result => {
        this.set("stats", result.stats);
        this.set("model", result.posts);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("performingAction", false);
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
    confirmFailedPost(post) {
      this.set("performingAction", true);
      _siftQueue.default.confirmFailed(post).then(() => {
        this.get("model").removeObject(post);
        this.incrementProperty("stats.confirmed_failed");
        this.decrementProperty("stats.requires_moderation");
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("performingAction", false);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "confirmFailedPost", [_object.action]))();
    allowPost(post) {
      this.set("performingAction", true);
      _siftQueue.default.allow(post).then(() => {
        this.incrementProperty("stats.confirmed_passed");
        this.decrementProperty("stats.requires_moderation");
        this.get("model").removeObject(post);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("performingAction", false);
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "allowPost", [_object.action]))();
    dismiss(post) {
      this.set("performingAction", true);
      _siftQueue.default.dismiss(post).then(() => {
        this.get("model").removeObject(post);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("performingAction", false);
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "dismiss", [_object.action]))();
  }
  _exports.default = AdminPluginsSiftController;
});