define("discourse/plugins/discourse-sift/discourse/routes/admin-plugins-sift", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-sift/admin/models/sift-queue"], function (_exports, _discourse, _siftQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsSift extends _discourse.default {
    _enabled = false;
    _stats = null;
    model() {
      let self = this;
      return _siftQueue.default.findAll().then(function (result) {
        self._enabled = result.enabled;
        self._stats = result.stats;
        return result.posts;
      });
    }
    setupController(controller, model) {
      controller.setProperties({
        model,
        enabled: this._enabled,
        stats: this._stats
      });
    }
  }
  _exports.default = AdminPluginsSift;
});