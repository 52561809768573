define("discourse/plugins/discourse-sift/discourse/components/modal/report-reason", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} class="report-reason-modal">
    <:body>
      <div>
        {{radio-button
          class="report-reason-choice"
          name="disgreement_action"
          value="false_positive"
          selection=this.reported_reason
        }}
        {{i18n "sift.report_reason.false_positive.title"}}
      </div>
      <div>
        {{radio-button
          class="report-reason-choice"
          name="disgreement_action"
          value="too_strict"
          selection=this.reported_reason
        }}
        {{i18n "sift.report_reason.too_strict.title"}}
      </div>
      <div>
        {{radio-button
          class="report-reason-choice"
          name="disgreement_action"
          value="user_edited"
          selection=this.reported_reason
        }}
        {{i18n "sift.report_reason.user_edited.title"}}
      </div>
      <div>
        {{radio-button
          class="report-reason-choice"
          name="disgreement_action"
          value="other"
          selection=this.reported_reason
        }}
        {{i18n "sift.report_reason.other.title"}}
        {{input name="other_reason" value=this.other_reason}}
      </div>
  
      {{d-button
        label="sift.report_reason.submit_button.title"
        class="btn-primary"
        action=(action "submitReport")
        disabled=this.attachDisabled
      }}
    </:body>
  </DModal>
  */
  {
    "id": "z06Ez8rk",
    "block": "[[[8,[39,0],[[24,0,\"report-reason-modal\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"class\",\"name\",\"value\",\"selection\"],[\"report-reason-choice\",\"disgreement_action\",\"false_positive\",[30,0,[\"reported_reason\"]]]]]],[1,\"\\n      \"],[1,[28,[35,4],[\"sift.report_reason.false_positive.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"class\",\"name\",\"value\",\"selection\"],[\"report-reason-choice\",\"disgreement_action\",\"too_strict\",[30,0,[\"reported_reason\"]]]]]],[1,\"\\n      \"],[1,[28,[35,4],[\"sift.report_reason.too_strict.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"class\",\"name\",\"value\",\"selection\"],[\"report-reason-choice\",\"disgreement_action\",\"user_edited\",[30,0,[\"reported_reason\"]]]]]],[1,\"\\n      \"],[1,[28,[35,4],[\"sift.report_reason.user_edited.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"class\",\"name\",\"value\",\"selection\"],[\"report-reason-choice\",\"disgreement_action\",\"other\",[30,0,[\"reported_reason\"]]]]]],[1,\"\\n      \"],[1,[28,[35,4],[\"sift.report_reason.other.title\"],null]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\"],[\"other_reason\",[30,0,[\"other_reason\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,6],null,[[\"label\",\"class\",\"action\",\"disabled\"],[\"sift.report_reason.submit_button.title\",\"btn-primary\",[28,[37,7],[[30,0],\"submitReport\"],null],[30,0,[\"attachDisabled\"]]]]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\":body\",\"div\",\"radio-button\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-sift/discourse/components/modal/report-reason.hbs",
    "isStrictMode": false
  });
  class ReportReason extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "reported_reason", [_tracking.tracked], function () {
      return null;
    }))();
    #reported_reason = (() => (dt7948.i(this, "reported_reason"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "other_reason", [_tracking.tracked], function () {
      return null;
    }))();
    #other_reason = (() => (dt7948.i(this, "other_reason"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "callback", [_tracking.tracked], function () {
      return null;
    }))();
    #callback = (() => (dt7948.i(this, "callback"), void 0))();
    attachDisabled(reported_reason, other_reason) {
      if (reported_reason === "other") {
        return !other_reason || other_reason.length === 0;
      } else {
        return !reported_reason;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "attachDisabled", [(0, _decorators.default)("reported_reason", "other_reason")]))();
    submitReport() {
      if (this.model.callback) {
        this.model.callback(this.reported_reason, this.other_reason);
      }
      this.closeModal();
    }
    static #_5 = (() => dt7948.n(this.prototype, "submitReport", [_object.action]))();
  }
  _exports.default = ReportReason;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReportReason);
});